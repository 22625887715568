import { differenceInYears } from 'date-fns';
import dayjs from 'dayjs';
import {
  Companion,
  CompanionEnum,
  Customer,
  CustomerEnum,
} from '../BookingWidget/BookingManagment/bookingManagment.interface';
import {
  FormQuotationInformationEnum,
  FormQuotationInformationPersonalData,
  FormQuotationInformationTravellerList,
} from './quoation-information.interface';
import { BookingSubStepsQuotationForm } from '../../context/bookingContext/bookingContext.types';

const prepareInitInformationPersonal = (
  customer: Customer,
): FormQuotationInformationPersonalData => ({
  [FormQuotationInformationEnum.Title]: customer[CustomerEnum.Title] as string,
  [FormQuotationInformationEnum.Firstname]: customer[
    CustomerEnum.FirstName
  ] as string,
  [FormQuotationInformationEnum.Lastname]: customer[
    CustomerEnum.LastName
  ] as string,
  [FormQuotationInformationEnum.Email]: customer[
    CustomerEnum.EmailAddress
  ] as string,
  [FormQuotationInformationEnum.ReenterEmail]:
    customer[CustomerEnum.EmailAddress],
  [FormQuotationInformationEnum.Phone]: customer[
    CustomerEnum.PhoneNumber1
  ] as string,
  [FormQuotationInformationEnum.Address]: customer[
    CustomerEnum.AddressLine1
  ] as string,
  [FormQuotationInformationEnum.Address2]: customer[
    CustomerEnum.AddressLine2
  ] as string,
  [FormQuotationInformationEnum.City]: customer[
    CustomerEnum.AddressLine3
  ] as string,
  [FormQuotationInformationEnum.State]: customer[
    CustomerEnum.AddressLine4
  ] as string,
  [FormQuotationInformationEnum.Postcode]: customer[
    CustomerEnum.PostalCode
  ] as string,
  [FormQuotationInformationEnum.Birthdate]: dayjs(
    customer[CustomerEnum.DateOfBirth],
  ).format('DD/MM/YYYY'),
});

const prepareInitInformationTravellers = (
  companions: Companion[],
): FormQuotationInformationTravellerList => {
  const travellers: FormQuotationInformationTravellerList = {};

  // eslint-disable-next-line array-callback-return
  companions.map((companion, index) => {
    const indexForPS = (index + 1).toString();
    travellers[
      `travellerFirstName${indexForPS}` as keyof FormQuotationInformationTravellerList
    ] = companion[CompanionEnum.FirstName];
    travellers[
      `travellerLastName${indexForPS}` as keyof FormQuotationInformationTravellerList
    ] = companion[CompanionEnum.LastName];
    travellers[
      `travellerAge${indexForPS}` as keyof FormQuotationInformationTravellerList
    ] = differenceInYears(
      new Date(),
      new Date(companion[CompanionEnum.DateOfBirth]),
    ).toString();
  });

  return travellers;
};

const normalizeDate = (date: string): string =>
  dayjs(date).format('YYYY-MM-DD');
const compareTravelCriteria = (
  quoteA: BookingSubStepsQuotationForm,
  quoteB: BookingSubStepsQuotationForm,
): boolean => {
  // Helper function to check deep equality of objects or arrays
  const isEqual = (objA: any, objB: any): boolean => {
    if (typeof objA !== typeof objB) return false;
    if (typeof objA === 'object' && objA !== null && objB !== null) {
      if (Array.isArray(objA) && Array.isArray(objB)) {
        if (objA.length !== objB.length) return false;
        return objA.every((item, index) => isEqual(item, objB[index]));
      }
      const keysA = Object.keys(objA);
      const keysB = Object.keys(objB);
      const sharedKeys = keysA.filter((key) => keysB.includes(key));
      return sharedKeys.every((key) => {
        if (key === 'departureDate' || key === 'returnDate') {
          return normalizeDate(objA[key]) === normalizeDate(objB[key]);
        }
        if (key === 'tripDetails' || key === 'travellersType') {
          return objA[key].value === objB[key].value;
        }
        if (key === 'promoCode') {
          return (!objA[key] && !objB[key]) || objA[key] === objB[key];
        }
        return isEqual(objA[key], objB[key]);
      });
    }
    return objA === objB;
  };
  return !isEqual(quoteA, quoteB);
};

export {
  prepareInitInformationPersonal,
  prepareInitInformationTravellers,
  compareTravelCriteria,
};
